
import { computed, defineComponent, watch } from "vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

import Comments from "components/comments/Comments.vue";
import CustomCard from "components/CustomCard.vue";

export default defineComponent({
  components: {
    CustomCard,
    Comments,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const changeNewsTitle = () => {
      if (store.state.news.selected) {
        document.title =
          store.state.news.selected.title + "- Klub Koneserów Kości";
      }
    };

    watch(
      () => route.params.id,
      (newValue) => {
        if (route.params.id) {
          store.dispatch("news/getSingle", +newValue).then(() => {
            changeNewsTitle();
          });
        }
      }
    );

    store.dispatch("news/getSingle", +route.params.id).then((s) => {
      changeNewsTitle();
    });

    return {
      news: computed(() => store.state.news.selected),
    };
  },
});
