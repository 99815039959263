
import { computed, defineComponent } from "vue";
import Bar from "components/Bar.vue";
import dayjs from "dayjs";

const CustomFooter = defineComponent({
  components: {
    Bar,
  },
  setup() {
    return {
      currentYear: computed(() => dayjs().year()),
    };
  },
});

export default CustomFooter;
