import BaseRequest from "@/store/base/BaseRequest";
import axios from "@/utils/axios";

export class UserRequest extends BaseRequest {
    constructor() {
        super('/Admin/User');
    }

    changePassword(id: number, newPassword: string) {
        return axios.post(this.route + '/ChangePassword', {id, newPassword});
    }

    changeRole(id: number, newRole: string) {
        return axios.post(this.route + '/ChangeRole', {id, newRole});
    }
}