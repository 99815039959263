import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7456d24c")
const _hoisted_1 = { class: "custom-card__header" }
const _hoisted_2 = { class: "custom-card__heading" }
const _hoisted_3 = { class: "custom-card__content" }
const _hoisted_4 = { class: "custom-card__slot" }
const _hoisted_5 = {
  key: 0,
  class: "custom-card__comments"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_news_description = _resolveComponent("news-description")!

  return (_openBlock(), _createElementBlock("div", {
    class: "custom-card",
    style: _normalizeStyle({ width: _ctx.w, height: _ctx.h })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (!!_ctx.newsInfo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_news_description, {
              addedBy: _ctx.newsInfo.addedBy,
              addedDate: _ctx.newsInfo.addedDate,
              commentsCount: _ctx.newsInfo.commentsCount,
              viewsCount: _ctx.newsInfo.viewsCount,
              commentsTo: _ctx.newsInfo.commentsTo
            }, null, 8, ["addedBy", "addedDate", "commentsCount", "viewsCount", "commentsTo"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}