import { IBaseEntity } from "@/store/base/BaseEntity";
import { IBaseState } from "@/store/base/BaseState";

export type PanelState = IBaseState<Panel>

export interface Panel extends IBaseEntity {
    title: string;
    location: PanelLocation,
    type: PanelType
    body: string;
}

export enum PanelLocation {
    LEFT,
    RIGHT,
    TOP
}

export const PanelLocationTranslation = {
    [PanelLocation.LEFT]: "Lewa strona",
    [PanelLocation.RIGHT]: "Prawa strona",
    [PanelLocation.TOP]: "Góra",
}


export enum PanelType {
    STANDARD,
    MENU,
    CHAT
}

export const PanelTypeName: { [key: number]: string } = {
    [PanelType.STANDARD]: "Standard",
    [PanelType.MENU]: "Menu",
    [PanelType.CHAT]: "Chat",
}

export const PanelTypeTranslation = {
    [PanelType.STANDARD]: "Standardowy",
    [PanelType.MENU]: "Nawigacja",
    [PanelType.CHAT]: "Czat",
}

export function getLocationValues() {
    return Object.keys(PanelLocation).filter((key: string | number) => isNaN(+key)).map((key) => ({
        label: (PanelLocationTranslation as any)[PanelLocation[key as any]],
        value: PanelLocation[key as any]
    }));
}


export function getTypeValues() {
    return Object.keys(PanelType).filter((key: string | number) => isNaN(+key)).map((key) => ({
        label: (PanelTypeTranslation as any)[PanelType[key as any]],
        value: PanelType[key as any]
    }));
}