<template>
  <div class="comments">
    <div class="comments__header">
      <h2 class="comments__heading">Dołącz do dyskusji</h2>
      <div>
        <span>Udział w dyskusji mogą brać jedynie zalogowani użytkownicy.</span>
      </div>
      <div class="comments__count">
        <span>
          <h4>{{ commentDescription }}</h4>
        </span>
      </div>
    </div>
    <n-form
        class="comments__form"
        :label-width="80"
        :model="model"
        :rules="rules"
        size="medium"
        ref="formRef"
    >
      <n-form-item label="Twój komentarz" path="message">
        <n-input
          width="100%"
          maxlength="250"
          show-count
          v-model:value="model.message"
          type="textarea"
          placeholder="Twój komentarz"
        />
      </n-form-item>

      <n-space justify="flex-end">
        <n-button type="primary" @click="addComment">Wyślij</n-button>
        <n-button @click="refreshComments">Odśwież</n-button>
      </n-space>
    </n-form>
    
    <comment-item v-for="comment of comments"  :key="comment.id" :comment="comment" />

  </div>
</template>

<script>
import {defineComponent, ref, computed} from "vue";
import {NForm, NFormItem, NInput, NSpace, NButton} from "naive-ui";
import {useRoute} from "vue-router";

import {useStore} from "@/store";
import {getCommentCountDescription} from "@/utils/comment";
import Utils from "utils/utils";
import CommentItem from "components/comments/CommentItem";

export default defineComponent({
  props: {},
  components: {
    CommentItem,
    NForm,
    NFormItem,
    NInput,
    NSpace,
    NButton,
  },
  setup() {
    const store = useStore();
    const formRef = ref(null);
    const route = useRoute();
    const model = ref({
      message: "",
    });
    const comments = computed(() => store.state.comments.data);
    const refreshComments = () => store.dispatch('comments/get', {options: {linkedEntityUrl: route.params.id}});

    refreshComments();

    return {
      formRef,
      refreshComments,
      addComment: (e) => {
        e.preventDefault();
        formRef.value.validate((errors) => {
          if (errors) {
            window.$message.error('Występują błędy w formularzu.')
            return;
          }

          store.dispatch('comments/add', {
            data: model.value,
            options: {linkedEntityUrl: route.params.id}
          }).then(() => refreshComments());
        })
      },
      commentDescription: computed(() => getCommentCountDescription(comments.value ? comments.value.length : 0)),
      comments,
      model,
      rules: {
        message: {
          required: true,
          message: "Twój komentarz nie może być pusty",
          trigger: ['input', 'blur']
        },
      },
    };
  }
});
</script>

<style scoped>
.comments {
  border-top: 2px solid var(--font-main-color);
  padding: var(--padding-medium);
  margin-top: var(--margin-default);
}

.comments__heading {
  color: var(--font-brown-color);
  font-size: var(--comment-section-heading);
}

.comments__header {
  margin-bottom: var(--margin-medium);
}

.comments__form {
  margin-bottom: var(--margin-medium);
}
</style>