
import { computed, defineComponent, onMounted, watch } from "vue";

import Panel from "components/Panels/Panel.vue";
import CustomFooter from "components/CustomFooter.vue";

import { useStore } from "@/store";
import { PanelLocation } from "@/store/modules/panels/types";
import { NGridItem, NGrid, NMessageProvider } from "naive-ui";
import LoginForm from "@/components/forms/LoginForm.vue";
import { UserLoginDto } from "@/store/modules/auth/types";
import UserPanel from "@/components/UserPanel.vue";
import { useRoute, useRouter } from "vue-router";

import AppLoader from "@/components/AppLoader.vue";
import AlertComponent from "@/components/AlertComponent.vue";

const App = defineComponent({
  components: {
    AppLoader,
    UserPanel,
    LoginForm,
    NGrid,
    NGridItem,
    NMessageProvider,
    AlertComponent,
    Panel,
    CustomFooter,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    store.dispatch("panel/get");
    store.dispatch("auth/getSession");

    const changeTitle = () => {
      if (route.meta.title === "selectTitle") {
        const page = store.state.menu.data.find(
          (el) => el.redirect === route.path
        );
        document.title = `${
          page ? page.title + " - " : ""
        }Klub Koneserów Kości`;
      } else {
        if (route.meta.title) {
          document.title =
            (route.meta.title as string) + " - Klub Koneserów Kości";
        } else {
          document.title = "Klub Koneserów Kości";
        }
      }
    };

    store.dispatch("menu/get").then(() => {
      changeTitle();
    });

    watch(() => route.path, changeTitle);

    onMounted(() => {
      changeTitle();
    });

    return {
      isLoading: computed<boolean>(
        () => store.state.panel.isLoading || store.state.menu.isLoading
      ),
      panels: computed(() => store.state.panel),
      user: computed(() => store.state.auth.selected),
      leftPanels: computed(() =>
        store.state.panel.data.filter(
          (el) => el.location === PanelLocation.LEFT
        )
      ),
      topPanels: computed(() =>
        store.state.panel.data.filter((el) => el.location === PanelLocation.TOP)
      ),
      rightPanels: computed(() =>
        store.state.panel.data.filter(
          (el) => el.location === PanelLocation.RIGHT
        )
      ),
      onLoginSubmit: (data: UserLoginDto) => {
        store.dispatch("auth/setLogin", data);
      },
      onLogoClick: () => {
        router.push({
          name: "home",
          query: {
            skip: 0,
            take: 10,
            page: 1,
          },
        });
      },
    };
  },
});

export default App;
