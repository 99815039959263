<template>
  <n-form :model="model" ref="formRef" :rules="rules">
    <n-form-item path="oldPassword" label="Poprzednie hasło">
      <n-input v-model:value="model.oldPassword" placeholder="" type="password"/>
    </n-form-item>

    <n-form-item path="newPassword" label="Nowe hasło">
      <n-input v-model:value="model.newPassword" placeholder="" type="password"/>
    </n-form-item>

    <n-form-item path="newPasswordRepeat" label="Powtórz nowe hasło">
      <n-input v-model:value="model.newPasswordRepeat" placeholder="" type="password"/>
    </n-form-item>


    <div style="display: flex; justify-content: center;">
      <n-button
          type="primary"
          ghost
          @click="onFormSubmit"
      >
        Zmień hasło
      </n-button>
    </div>
  </n-form>
</template>

<script>
import {defineComponent, ref} from "vue";

import {NFormItem, NForm, NInput, NButton} from 'naive-ui'
import {compare} from "@/utils/validators";
import {useStore} from '@/store';

const emptyPasswordForm= () =>({oldPassword: '', newPassword: '', newPasswordRepeat: ''});

export default defineComponent({
  components: {NFormItem, NForm, NInput, NButton},
  setup() {
    const model = ref(emptyPasswordForm());
    const formRef = ref(null);
    const store = useStore();

    return {
      formRef,
      model,
      rules: {
        oldPassword: {
          required: true,
          message: 'Należy podać poprzednie hasło',
          trigger: ['input', 'blur']
        },
        newPassword: {
          required: true,
          message: 'Należy podać nowe hasło',
          trigger: ['input', 'blur']
        },
        newPasswordRepeat: [{
          required: true,
          message: 'Należy powtórzyć nowe hasło',
          trigger: ['input', 'blur']
        }, {
          validator: () => compare(model.value.newPassword, model.value.newPasswordRepeat),
          message: 'Hasła muszą być takie same',
          trigger: ['input', 'blur']
        }]
      },
      onFormSubmit: (e) => {
        e.preventDefault();
        formRef.value.validate((errors) => {
          if (errors) {
            return;
          }

          store
              .dispatch('auth/changePassword', model.value).then((response) => response.status < 400 ? window.$message.success('Zmieniłeś/aś swoje hasło!') : null)
              .then(() => (model.value = emptyPasswordForm()))
        });
      }
    }
  }
})
</script>

<style scoped>

</style>