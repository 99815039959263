import axios, {AxiosResponse} from 'axios';
import router from '@/router';

import {BASE_URL} from "@/constants/constants";
import Utils from "@/utils/utils";
import {DomainExceptionCode, DomainExceptionCodeTranslation} from "@/enums/errors";


interface IDomainError {
    description: string;
    domainExceptionCode: string;
}

const instance = axios.create({
    baseURL: BASE_URL
});

function handleNotFoundError(response: AxiosResponse) {
    console.log('ok')
}

function handleInternalError(response: AxiosResponse) {
    console.log('ok')
}

function handleForbiddenError(response: AxiosResponse) {
    window.$message.error('Niewystarczające uprawnienia by wyświetlić tę zawartość. Zaloguj się na konto z wystarczającymi uprawnieniami!')
    router.push({name: 'home'})
}

function handleUnauthorizedError(response: AxiosResponse) {
    window.$message.error('Zaloguj się, aby wykonać poniższą akcję')
    router.push({name: 'home'})
}

function handleBadRequestError(response: AxiosResponse) {
    if (!response.data || !response.data.domainExceptionCode) {
        return;
    }
    const data = <IDomainError>response.data;
    const errorType: any = DomainExceptionCode[data.domainExceptionCode as any];
    if(!errorType) {
        return;
    }
    
    window.$message.error((DomainExceptionCodeTranslation as any)[errorType] || data.domainExceptionCode)
}

instance.interceptors.request.use((config) => {
    const token = Utils.getAuthToken();
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
})


instance.interceptors.response.use((response) => response, error => {
    const response = error.response;
    console.log('response', response.status, response.statusText, response.config.url)
    switch (response.status) {
        case 403: {
            handleForbiddenError(response);
            break;
        }
        case 401: {
            handleUnauthorizedError(response);
            break;
        }
        case 400: {
            handleBadRequestError(response);
            break;
        }
    }

    return response;
});
export default instance;