import {computed, ref, onMounted} from "vue";

import {useStore} from '@/store';
import {getColumns, IButtonItem, ITableItem} from "@/utils/table";

function createPaginationData() {
    return ref({
        pageSize: 10,
        itemCount: 10,
        prefix(data: any) {
            return `Znaleziono ${data.itemCount} elementów.`
        }
    })
}

export function usePagination(moduleName: string, columns: ITableItem[], refreshOnSave= false, rowButtons: IButtonItem[] = []) {
    const adminStateName = 'admin' + moduleName;
    const userStateName = moduleName.toLowerCase();
    const store = useStore()
    const list = computed(() => (store.state as any)[adminStateName]);
    const loading = ref(true);
    const pagination = createPaginationData();

    const getList = (pageNumber = 1) => {
        loading.value = true;
        return store.dispatch(adminStateName + '/get', {
            take: pagination.value.pageSize,
            skip: (pageNumber - 1) * pagination.value.pageSize
        }).then(() => {
            pagination.value.itemCount = list.value.total;
            loading.value = false;
        }).catch(() => {
            loading.value = false;
        })
    }

    onMounted(() => {
        getList();
    })

    const onDelete = (id: number) => {
        if (confirm("Czy jesteś pewien, że chcesz usunąć ten element?")) {
            store.dispatch(adminStateName + '/delete', id).then(() => {
                getList();

                if (refreshOnSave) {
                    store.dispatch(userStateName + '/get');
                }
            })
        }
    }

    const onActive = (id: number, active: boolean) => {
        store.dispatch(adminStateName + '/setActive', {id, active}).then(() => {
            getList();

            if (refreshOnSave) {
                store.dispatch(userStateName + '/get');
            }
        })
    }

    return {
        pagination,
        loading,
        columns: getColumns(columns, {
            edit: 'admin-' + moduleName.toLowerCase() + '-edit',
            delete: onDelete,
            active: onActive
        }, rowButtons),
        list,
        onPageChange: (page: any) => {
            getList(page);
        }
    };
}