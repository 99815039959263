import * as Icons from "@vicons/ionicons5";
import {h} from "vue";
import {NIcon} from "naive-ui";
const icons = Icons as { [key: string]: unknown };

export function renderIcon(icon: string | any) {
    if (typeof icon === 'string') {
        return () => h(NIcon, null, {default: () => h(icons[icon] as any)});
    } else {
        return () => h(NIcon, null, {default: () => h(icon)});
    }
}