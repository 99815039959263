import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "sidebar" }
const _hoisted_4 = { class: "main" }
const _hoisted_5 = { class: "rightbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_loader = _resolveComponent("app-loader")!
  const _component_panel = _resolveComponent("panel")!
  const _component_n_grid_item = _resolveComponent("n-grid-item")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_user_panel = _resolveComponent("user-panel")!
  const _component_login_form = _resolveComponent("login-form")!
  const _component_n_grid = _resolveComponent("n-grid")!
  const _component_custom_footer = _resolveComponent("custom-footer")!
  const _component_alert_component = _resolveComponent("alert-component")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_app_loader, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("img", {
        width: "700",
        class: "header__logo",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLogoClick && _ctx.onLogoClick(...args))),
        alt: "logo",
        src: require('@/assets/logo.png')
      }, null, 8, _hoisted_2)
    ]),
    _createVNode(_component_n_grid, {
      responsive: "screen",
      class: "content",
      cols: "10 2xl:14"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_n_grid_item, { span: "3" }, {
          default: _withCtx(() => [
            _createElementVNode("aside", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leftPanels, (leftPanel) => {
                return (_openBlock(), _createBlock(_component_panel, {
                  class: "home-card",
                  key: leftPanel.id,
                  title: leftPanel.title,
                  componentProps: { _html: leftPanel.body },
                  name: leftPanel.type
                }, null, 8, ["title", "componentProps", "name"]))
              }), 128))
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_n_grid_item, { span: "4 2xl:8" }, {
          default: _withCtx(() => [
            _createElementVNode("main", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topPanels, (topPanel) => {
                return (_openBlock(), _createBlock(_component_panel, {
                  class: "home-card",
                  key: topPanel.id,
                  title: topPanel.title,
                  componentProps: { _html: topPanel.body },
                  name: topPanel.type
                }, null, 8, ["title", "componentProps", "name"]))
              }), 128)),
              _createVNode(_component_router_view)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_n_grid_item, { span: "3" }, {
          default: _withCtx(() => [
            _createElementVNode("aside", _hoisted_5, [
              (_ctx.user)
                ? (_openBlock(), _createBlock(_component_user_panel, {
                    key: 0,
                    user: _ctx.user
                  }, null, 8, ["user"]))
                : (_openBlock(), _createBlock(_component_login_form, {
                    key: 1,
                    onSubmit: _ctx.onLoginSubmit
                  }, null, 8, ["onSubmit"])),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rightPanels, (rightPanel) => {
                return (_openBlock(), _createBlock(_component_panel, {
                  class: "home-card",
                  key: rightPanel.id,
                  title: rightPanel.title,
                  componentProps: { _html: rightPanel.body },
                  name: rightPanel.type
                }, null, 8, ["title", "componentProps", "name"]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_custom_footer),
    _createVNode(_component_n_message_provider, null, {
      default: _withCtx(() => [
        _createVNode(_component_alert_component)
      ]),
      _: 1
    })
  ], 64))
}