import { RouteRecordRaw } from "vue-router";
import AdminNewsList from "@/views/admin/news/List.vue";
import AdminNewsEdit from "@/views/admin/news/Edit.vue";
import AdminPanelList from "@/views/admin/panel/List.vue";
import AdminPanelEdit from "@/views/admin/panel/Edit.vue";
import AdminPageList from "@/views/admin/page/List.vue";
import AdminPageEdit from "@/views/admin/page/Edit.vue";
import AdminMenuList from "@/views/admin/menu/List.vue";
import AdminMenuEdit from "@/views/admin/menu/Edit.vue";
import AdminUserList from "@/views/admin/user/List.vue";
import AdminUserEdit from "@/views/admin/user/Edit.vue";
import AdminUserAdd from "@/views/admin/user/Add.vue";
import AdminLayout from "@/views/admin/Layout.vue";

export const adminRoutes: Array<RouteRecordRaw> = [
    {
        path: '/admin',
        children: [
            {
                name: 'admin-news',
                path: 'news',
                meta: { title: 'Newsy' },
                component: AdminNewsList
            },
            {
                name: 'admin-news-edit',
                path: 'news-edit',
                meta: { title: "Zarządzaj Newsami" },
                component: AdminNewsEdit
            },
            {
                name: 'admin-panel',
                path: 'panel',
                meta: { title: "Panele" },
                component: AdminPanelList
            },
            {
                name: 'admin-panel-edit',
                path: 'panel-edit',
                meta: { title: "Zarządzaj Panelami" },
                component: AdminPanelEdit
            },
            {
                name: 'admin-page',
                path: 'page',
                meta: { title: "Strony" },
                component: AdminPageList
            },
            {
                name: 'admin-page-edit',
                path: 'page-edit',
                meta: { title: "Zarządzaj Stronami" },
                component: AdminPageEdit
            },
            {
                name: 'admin-user',
                path: 'user',
                meta: { title: "Użytkownicy" },
                component: AdminUserList
            },
            {
                name: 'admin-user-edit',
                path: 'user-edit',
                meta: { title: "Zarządzaj Użytkownikami" },
                component: AdminUserEdit
            },
            {
                name: 'admin-user-add',
                path: 'user-add',
                meta: { title: "Zarządzaj Użytkownikami" },
                component: AdminUserAdd
            },
            {
                name: 'admin-menu',
                path: 'menu',
                meta: { title: "Manu" },
                component: AdminMenuList
            },
            {
                name: 'admin-menu-edit',
                path: 'menu-edit',
                meta: { title: "Zarządzaj Menu" },
                component: AdminMenuEdit
            }
        ],
        component: AdminLayout
    },
]