import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_card = _resolveComponent("custom-card")!

  return (_ctx.page)
    ? (_openBlock(), _createBlock(_component_custom_card, {
        key: 0,
        title: _ctx.page.title
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            innerHTML: _ctx.page.body
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}