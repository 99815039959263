import { doAsyncAction } from "@/store/base/BaseAction";
import { Id } from "@/store/base/BaseEntity";
import baseMutations from "@/store/base/BaseMutations";
import BaseRequest, { CollectionParams } from "@/store/base/BaseRequest";
import { BaseState } from "@/store/base/BaseState";
import cloneDeep from "lodash.clonedeep";
import { Module } from "vuex";
import { Menu, MenuState } from "./types";

const menuRequests = new BaseRequest("/Public/Menu");

export const MenuModule: Module<MenuState, null> = {
  namespaced: true,
  state: new BaseState<Menu>(),
  mutations: {
    ...cloneDeep(baseMutations),
  },
  actions: {
    get(store, params: CollectionParams) {
      return doAsyncAction(store, {
        mutationType: "setCollection",
        action: () => menuRequests.getCollection(params)
      })
    },
    getSingle(store, id: Id) {
      return doAsyncAction(store, {
        mutationType: "setSelected",
        action: () => menuRequests.getSingle(id)
      })
    }
  }
}