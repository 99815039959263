<template>
  <n-form :model="model" ref="formRef" :rules="rules">
    <n-form-item path="newPassword" label="Nowe hasło">
      <n-input v-model:value="model.newPassword" placeholder="" type="password"/>
    </n-form-item>

    <n-form-item path="newPasswordRepeat" label="Powtórz hasło">
      <n-input v-model:value="model.newPasswordRepeat" placeholder="" type="password"/>
    </n-form-item>

    <div style="display: flex; justify-content: center;">
      <n-button
          type="primary"
          ghost
          @click="onFormSubmit"
      >
        Zmień hasło
      </n-button>
    </div>
  </n-form>
</template>

<script>
import {defineComponent, ref} from "vue";
import {NForm, NFormItem, NButton, NInput} from 'naive-ui';

import {compare} from "@/utils/validators";

export default defineComponent({
  components: {NForm, NFormItem, NButton, NInput},
  setup(_, {emit}) {
    const model = ref({newPassword: '', newPasswordRepeat: ''})
    const formRef = ref(null);
    return {
      model,
      formRef,
      rules: {
        newPassword: {
          required: true,
          message: 'Należy podać nowe hasło',
          trigger: ['input', 'blur']
        },
        newPasswordRepeat: [{
          required: true,
          message: 'Należy powtórzyć nowe hasło',
          trigger: ['input', 'blur']
        }, {
          validator: () => compare(model.value.newPassword, model.value.newPasswordRepeat),
          message: 'Hasła muszą być takie same',
          trigger: ['input', 'blur']
        }]
      },
      onFormSubmit: (e) => {
        e.preventDefault();

        formRef.value.validate((errors) => {
          if (errors) {
            return;
          }

          emit('submit', model.value);
        });
      }
    }
  }
});
</script>

<style scoped>

</style>