import BaseRequest, { CollectionParams } from "@/store/base/BaseRequest";
import { BaseState } from "@/store/base/BaseState";
import cloneDeep from "lodash.clonedeep";
import { Module } from "vuex";
import { ChatMessage, ChatState } from "./types";
import baseMutations from "@/store/base/BaseMutations";
import { doAsyncAction } from "@/store/base/BaseAction";
import { Id } from "@/store/base/BaseEntity";


const chatRequests = new BaseRequest("/User/Chat");

export const ChatModule: Module<ChatState, null> = {
  namespaced: true,
  state: new BaseState<ChatMessage>(),
  mutations: {
    ...cloneDeep(baseMutations),
  },
  actions: {
    get(store, params: CollectionParams) {
      return doAsyncAction(store, {
        mutationType: "setCollection",
        action: () => chatRequests.getCollection(params)
      })
    },
    getSingle(store, id: Id) {
      return doAsyncAction(store, {
        mutationType: "setSelected",
        action: () => chatRequests.getSingle(id)
      })
    },
    add(store, data: ChatMessage) {
      return chatRequests.create(data);
    }
  }
}