<template>
  <n-button class="text-right">
    <custom-link :to="{name: 'admin-user-add'}">Dodaj nowy</custom-link>
  </n-button>

  <n-data-table remote :columns="columns" :data="list.data" :pagination="pagination" :loading="loading"
                @update:page="onPageChange"/>

  <n-modal v-model:show="passwordModal">
    <n-card style="width: 600px;" title="Zmień hasło" :bordered="false" size="huge">
      <change-password @submit="onPasswordSubmit"/>
    </n-card>
  </n-modal>

  <n-modal v-model:show="roleModal">
    <n-card style="width: 600px;" title="Zmień role" :bordered="false" size="huge">
      <change-role :current-role="currentEditUser.role.name" :roles="roles" @submit="onRoleSubmit"/>
    </n-card>
  </n-modal>
</template>

<script>
import {computed, defineComponent, ref} from "vue";
import {NDataTable, NButton, NModal, NCard} from 'naive-ui'
import {useStore} from "@/store";

import {usePagination} from "@/utils/pagination";
import CustomLink from "components/CustomLink.vue";
import ChangePassword from './modal/ChangePassword.vue';
import ChangeRole from './modal/ChangeRole.vue';


export default defineComponent({
  components: {NDataTable, CustomLink, NButton, NModal, ChangePassword, NCard, ChangeRole},
  setup() {
    const store = useStore();
    const onChangePassword = (user) => {
      currentEditUser.value = user;
      passwordModal.value = true;
    }
    const onChangeRole = (user) => {
      currentEditUser.value = user;
      roleModal.value = true;
    }
    
    store.dispatch('adminRole/get');

    const currentEditUser = ref({});
    const passwordModal = ref(false);
    const roleModal = ref(false);
    
    const roles = computed(() => store.state.adminRole.data);

    const onRoleSubmit = (data)  =>
        store.dispatch('adminUser/changeRole', {id: currentEditUser.value.id, newRole: data.role})
            .then((response) => response.status < 400 ? window.$message.success('Poprawnie zmieniono rolę') : null)
            .then(() => (roleModal.value = false));

    const onPasswordSubmit = (data) =>
        store.dispatch('adminUser/changePassword', {id: currentEditUser.value.id, newPassword: data.newPassword})
            .then((response) => response.status < 400 ? window.$message.success('Poprawnie zmieniono hasło') : null)
            .then(() => (passwordModal.value = false));

    const buttons = [{
      icon: 'LockClosed',
      onClick: (id) => onChangePassword(id),
      tooltip: 'Zmień hasło',
      type: 'warning'
    }, {
      icon: 'School',
      tooltip: 'Zmień uprawnienia',
      onClick: (id) => onChangeRole(id)
    }];

    return {
      ...usePagination('User', [
        {title: 'Id', key: 'id'},
        {title: 'Adres e-mail', key: 'email'},
        {title: 'Nazwa użytkownika', key: 'nickname'},
        {title: 'Rola', key: 'role', render: (row) => row.role.displayName},
      ], true, buttons), ...{
        passwordModal, currentEditUser, roleModal, onPasswordSubmit, onRoleSubmit, roles
      }
    }
  },
});
</script>
