import BaseRequest from "@/store/base/BaseRequest";
import axios from "@/utils/axios";

export class UserRequest extends BaseRequest {
    constructor() {
        super('/User/Auth');
    }
    
    login<Response>(username: string, password: string) {
        return axios.post(this.route, {username, password});
    }
    
    changePassword<Response>(oldPassword: string, newPassword: string) {
        return axios.post(this.route + '/ChangePassword', {oldPassword, newPassword});
    }
    
    getSession<Response>() {
        return axios.get(this.route);
    }
}