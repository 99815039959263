import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0a8346ac")
const _hoisted_1 = { class: "chat__date-container" }
const _hoisted_2 = { class: "chat__date" }
const _hoisted_3 = { class: "chat__nickname" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_gi = _resolveComponent("n-gi")!
  const _component_n_grid = _resolveComponent("n-grid")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chat__message", { userOwned: _ctx.isUser }])
  }, [
    _createVNode(_component_n_grid, { cols: 12 }, {
      default: _withCtx(() => [
        _createVNode(_component_n_gi, { span: 12 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.message), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_n_gi, { span: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formattedDate), 1),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.nickname), 1)
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}