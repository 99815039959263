<template>
  <custom-card title="Użytkownik" class="home-card">
    <router-view />
  </custom-card>
</template>

<script>
import { defineComponent } from "vue";

import CustomCard from "@/components/CustomCard";


export default defineComponent({
  components: {  CustomCard }
});
</script>
