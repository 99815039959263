import {h} from 'vue';
import {NButton} from "naive-ui";

import CustomLink from "../components/CustomLink.vue";
import {renderIcon} from "@/utils/icons";
import {createTooltip} from "@/utils/tooltip";

export interface ITableButtonProps {
    delete: (id: number) => any;
    active: (id: number, active: boolean) => any;
    edit: string;
}

export interface ITableItem {
    key: string;
    title: string;
    render?: (row: any) => any;
}

export interface IButtonItem {
    tooltip: string;
    onClick: (row: any) => void;
    type?: 'default' | 'primary' | 'info' | 'success' | 'warning' | 'error' | undefined;
    icon: string;
}

function createLink(icon: string, to: string | any) {
    return h(CustomLink, {to, tooltip: 'edytuj'}, renderIcon(icon));
}

function createEditButton(icon: string, id: number, redirectName: string) {
    return h(NButton, {size: 'tiny', style: 'margin-right: 5px;'}, [createLink(icon, {
        name: redirectName,
        query: {id}
    })]);
}

function createDeleteButton(icon: string, id: number, callback: (id: number) => any) {
    return h(NButton, {
        size: 'tiny',
        type: 'error',
        style: 'margin-right: 5px;',
        onClick: () => callback(id)
    }, renderIcon(icon))
}

function createToggleActiveButton(icon: string, id: number, active: boolean, callback: (id: number, active: boolean) => any) {
    let type: 'error' | 'success' = 'success';
    if (!active) {
        type = 'error';
    }

    return h(NButton, {
        size: 'tiny',
        type,
        style: 'margin-right: 5px;',
        onClick: () => callback(id, !active)
    }, renderIcon(icon))
}

function createButton(data: IButtonItem, row: any) {
    return h(NButton, {
        size: "tiny",
        type: data.type,
        style: 'margin-right: 5px;',
        onClick: () => data.onClick(row)
    }, renderIcon(data.icon));
}


function createRowButtons(props: ITableButtonProps, additionalButtons: IButtonItem[] = []): ITableItem[] {
    return [
        {
            key: 'edit_column',
            title: 'Akcje',
            render: (row) => h('div', [
                createTooltip('Edytuj element', createEditButton('Pencil', row.id, props.edit)),
                createTooltip('Usuń element', createDeleteButton('Trash', row.id, props.delete)),
                createTooltip('Zmień aktywność', createToggleActiveButton('Toggle', row.id, row.active, props.active)),
                ...additionalButtons.map(button => createTooltip(button.tooltip, createButton(button, row)))
            ])
        }
    ];
}

export function getColumns(columns: ITableItem[] = [], props: ITableButtonProps, buttons: IButtonItem[] = []) {
    return [...columns, ...createRowButtons(props, buttons)];
}