import { RouteRecordRaw } from "vue-router";

import UserLayout from '@/views/user/Layout.vue';
import UserChangePassword from '@/views/user/ChangePassword.vue';

export const userRoutes: Array<RouteRecordRaw> = [{
    path: '/user',
    meta: { title: "Użytkownik" },
    children: [
        {
            path: 'change-password',
            name: 'user-change-password',
            meta: { title: "Zmień hasło" },
            component: UserChangePassword
        }
    ],
    component: UserLayout
}];