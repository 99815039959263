<template>
  <n-form :model="model" ref="formRef" :rules="rules">
    <n-form-item path="role" label="Rola">
      <n-select v-model:value="model.role" :options="selectValues" placeholder=""/>
    </n-form-item>

    <div style="display: flex; justify-content: center;">
      <n-button
          type="primary"
          ghost
          @click="onFormSubmit"
      >
        Zmień rolę
      </n-button>
    </div>
  </n-form>
</template>

<script>
import {defineComponent, ref, computed} from "vue";
import {NForm, NFormItem, NButton, NInput, NSelect} from 'naive-ui';

import {compare} from "@/utils/validators";

export default defineComponent({
  props: {
    currentRole: {
      type: String,
      required: true
    },
    roles: {
      type: Array,
      required: true
    }
  },
  components: {NForm, NFormItem, NButton, NSelect},
  setup(props, {emit}) {
    const model = ref({role: props.currentRole});
    const formRef = ref(null);

    return {
      selectValues: computed(() => props.roles.map(role => ({label: role.displayName, value: role.name}))),
      model,
      formRef,
      rules: {
        role: {
          required: true,
          message: 'Należy wybrać rolę',
          trigger: ['input', 'blur']
        }
      },
      onFormSubmit: (e) => {
        e.preventDefault();

        formRef.value.validate((errors) => {
          if (errors) {
            return;
          }

          emit('submit', model.value);
        });
      }
    }
  }
});
</script>

<style scoped>

</style>