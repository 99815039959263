import {doAsyncAction} from "@/store/base/BaseAction";
import {Id} from "@/store/base/BaseEntity";
import baseMutations from "@/store/base/BaseMutations";
import BaseRequest, {CollectionParams} from "@/store/base/BaseRequest";
import {BaseState} from "@/store/base/BaseState";
import cloneDeep from "lodash.clonedeep";
import {Module} from "vuex";
import {Page, PageState} from "./types";
import {NewsEdit} from "@/store/modules/news/types";
import {IBasePatch} from "@/store/base/BasePatch";

const pageRequests = new BaseRequest("/Admin/Page");

export const AdminPageModule: Module<PageState, null> = {
    namespaced: true,
    state: new BaseState<Page>(),
    mutations: {
        ...cloneDeep(baseMutations),
    },
    actions: {
        get(store, params: CollectionParams) {
            return doAsyncAction(store, {
                mutationType: "setCollection",
                action: () => pageRequests.getCollection(params)
            })
        },
        getSingle(store, id: Id) {
            return doAsyncAction(store, {
                mutationType: "setSelected",
                action: () => pageRequests.getSingle(id)
            })
        },
        addOrUpdate(store, data: NewsEdit) {
            if (data.id) {
                return pageRequests.update(data);
            } else {
                return pageRequests.create(data);
            }
        },
        delete(store, id: number) {
            return pageRequests.delete(id);
        },
        setActive(store, data: IBasePatch) {
            return pageRequests.patch(data.id, data.active);
        }
    }
}