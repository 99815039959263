import { doAsyncAction } from "@/store/base/BaseAction";
import baseMutations from "@/store/base/BaseMutations";
import BaseRequest, { CollectionParams, RequestOptions } from "@/store/base/BaseRequest";
import { BaseState } from "@/store/base/BaseState";
import cloneDeep from "lodash.clonedeep";
import { Module } from "vuex";
import {CommentState, Comment, CommentAddDto} from "./types";
import {ChatMessage} from "@/store/modules/chat/types";

const commentsRequests = new BaseRequest("/User/News/{id}/Comment");

export const CommentsModule: Module<CommentState, null> = {
  namespaced: true,
  state: new BaseState<Comment>(),
  mutations: {
    ...cloneDeep(baseMutations),
  },
  actions: {
    get(store, config: { params: CollectionParams, options: RequestOptions }) {
      return doAsyncAction(store, {
        mutationType: "setCollection",
        action: () => commentsRequests.getCollection(config.params, config.options)
      })
    },
    add(store, config: {data: CommentAddDto, options: RequestOptions}) {
      return commentsRequests.create(config.data, config.options);
    }
  }
}