
import { computed, defineComponent, onMounted, ref } from "vue";
import { NPagination, NSpace } from "naive-ui";
import { useStore } from "@/store";

import CustomCard from "components/CustomCard.vue";
import { useRoute, useRouter } from "vue-router";

const Home = defineComponent({
  components: {
    CustomCard,
    NPagination,
    NSpace,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const sizePerPage = 10;
    let page = ref(1);

    onMounted(() => {
      if (route.query.page && !Array.isArray(route.query.page)) {
        page.value = parseInt(route.query.page);
      }
      store.dispatch("news/get", {
        skip: route.query.skip ? route.query.skip : 0,
        take: route.query.take ? route.query.take : sizePerPage,
      });
    });

    return {
      store,
      news: computed(() => store.state.news),
      page,
      sizePerPage,
      changePage: (paginationPage: number) => {
        const skip = sizePerPage * (paginationPage - 1);
        const take = sizePerPage;

        store.dispatch("news/get", {
          skip,
          take,
        });
        page.value = paginationPage;
        router.push({
          name: "home",
          query: { skip, take, page: paginationPage },
        });
        window.scrollTo(0, 0);
      },
    };
  },
});

export default Home;
