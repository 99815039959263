import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-09ef0c92")
const _hoisted_1 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_card = _resolveComponent("custom-card")!
  const _component_n_pagination = _resolveComponent("n-pagination")!
  const _component_n_space = _resolveComponent("n-space")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news.data, (newsItem) => {
      return (_openBlock(), _createBlock(_component_custom_card, {
        class: "home-card",
        key: newsItem.id,
        title: newsItem.title,
        newsInfo: {
      addedBy: newsItem.user?.nickname || 'admin',
      addedDate: newsItem.createdAt,
      commentsCount: newsItem.commentsCount,
      commentsTo: { name: 'news', params: { id: newsItem.id } }
    }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            innerHTML: newsItem.body
          }, null, 8, _hoisted_1)
        ]),
        _: 2
      }, 1032, ["title", "newsInfo"]))
    }), 128)),
    _createVNode(_component_n_space, { justify: "end" }, {
      default: _withCtx(() => [
        _createVNode(_component_n_pagination, {
          "on-update:page": _ctx.changePage,
          page: _ctx.page,
          "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => (_ctx.page = $event)),
          "page-count": Math.floor(_ctx.news.total / _ctx.sizePerPage)
        }, null, 8, ["on-update:page", "page", "page-count"])
      ]),
      _: 1
    })
  ], 64))
}