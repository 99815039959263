
import { computed, defineComponent } from "vue";
import CustomNavigation from "components/CustomNavigation.vue";
import { useStore } from "@/store";
import { getMenuOptions } from "@/utils/menu";

const Menu = defineComponent({
  components: {
    CustomNavigation,
  },
  setup() {
    const store = useStore();
    return {
      menuOptions: computed(() =>
        getMenuOptions(
          store.state.menu.data.map((el) => ({
            title: el.title,
            redirect: el.redirect,
            icon: el.icon,
            key: "menu_" + el.id,
            disabled: el.redirect === "---",
          }))
        )
      ),
    };
  },
});

export default Menu;
