<template>

    <div class="loader-container">
      <div class='loader'>
        <div class='loader-block'>
          <div class='loader-item'></div>
          <div class='loader-item'></div>
          <div class='loader-item'></div>
          <div class='loader-item'></div>
          <div class='loader-item'></div>
          <div class='loader-item'></div>
          <div class='loader-item'></div>
          <div class='loader-item'></div>
        </div>
      </div>
    </div>

</template>

<style scoped>
.loader-container {
  background: #618f4f;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 2rem;
  width: 2rem;
  transform: translateX(-50%) translateY(-50%);
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
}

.loader-block {
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
}

.loader-block > .loader-item {
  position: absolute;
  height: 2rem;
  width: 2rem;
  background: #fff;
  animation: move 2s linear infinite;
}

.loader-block > .loader-item:nth-of-type(1) {
  top: -2rem;
  left: -2rem;
  animation-delay: -1.75s;
}

.loader-block > .loader-item:nth-of-type(2) {
  top: -2rem;
  left: 0;
  animation-delay: -1.5s;
}

.loader-block > .loader-item:nth-of-type(3) {
  top: -2rem;
  left: 2rem;
  animation-delay: -1.25s;
}

.loader-block > .loader-item:nth-of-type(4) {
  top: 0;
  left: 2rem;
  animation-delay: -1s;
}

.loader-block > .loader-item:nth-of-type(5) {
  top: 2rem;
  left: 2rem;
  animation-delay: -0.75s;
}

.loader-block > .loader-item:nth-of-type(6) {
  top: 2rem;
  left: 0;
  animation-delay: -0.5s;
}

.loader-block > .loader-item:nth-of-type(7) {
  top: 2rem;
  left: -2rem;
  animation-delay: -0.25s;
}

.loader-block > .loader-item:nth-of-type(8) {
  top: 0;
  left: -2rem;
  animation-delay: 0s;
}

@keyframes move {
  0% {
    transform: rotate(0) scale(1);
    animation-timing-function: ease-in;
  }
  10% {
    transform: rotate(90deg) scale(0);
  }
  50% {
    transform: rotate(90deg) scale(0);
    animation-timing-function: ease-out;
  }
  60% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(180deg) scale(1);
  }
}

</style>