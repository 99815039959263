import { doAsyncAction } from "@/store/base/BaseAction";
import { Id } from "@/store/base/BaseEntity";
import baseMutations from "@/store/base/BaseMutations";
import BaseRequest, { CollectionParams } from "@/store/base/BaseRequest";
import { BaseState } from "@/store/base/BaseState";
import cloneDeep from "lodash.clonedeep";
import { Module } from "vuex";
import {IBasePatch} from "@/store/base/BasePatch";
import {User, UserChangePasswordDto, UserChangeRoleDto, UserState} from "@/store/modules/user/types";
import {UserRequest} from "@/store/modules/user/request";

const userRequest = new UserRequest();

export const AdminUserModule: Module<UserState, null> = {
  namespaced: true,
  state: new BaseState<User>(),
  mutations: {
    ...cloneDeep(baseMutations),
  },
  actions: {
    get(store, params: CollectionParams) {
      return doAsyncAction(store, {
        mutationType: "setCollection",
        action: () => userRequest.getCollection(params)
      })
    },
    getSingle(store, id: Id) {
      return doAsyncAction(store, {
        mutationType: "setSelected",
        action: () => userRequest.getSingle(id)
      })
    },
    addOrUpdate(store, data: User) {
      if(data.id) {
        return userRequest.update(data);
      }
      else {
        return userRequest.create(data);
      }
    },
    delete(store, id: number) {
      return userRequest.delete(id);
    },
    setActive(store, data: IBasePatch) {
      return userRequest.patch(data.id, data.active);
    },
    changePassword(store, data: UserChangePasswordDto) {
      return userRequest.changePassword(data.id, data.newPassword);
    },
    changeRole(store, data: UserChangeRoleDto) {
      return userRequest.changeRole(data.id, data.newRole);
    }
  }
}