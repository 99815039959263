<template>
  <n-button class="text-right">
    <custom-link :to="{name: 'admin-news-edit'}">Dodaj nowy</custom-link>
  </n-button>
  
  <n-data-table remote :columns="columns" :data="list.data" :pagination="pagination" :loading="loading"
                @update:page="onPageChange"/>
</template>

<script>
import {defineComponent} from "vue";
import {NDataTable, NButton} from 'naive-ui'

import {usePagination} from "@/utils/pagination";
import Utils from "@/utils/utils";
import CustomLink from "components/CustomLink.vue";


export default defineComponent({
  components: {NDataTable, CustomLink, NButton},
  setup() {
    return usePagination('News', [
      {title: 'Id', key: 'id'},
      {title: 'Tytuł', key: 'title'},
      {title: 'Data dodania', key: 'createdAt', render: (row) => Utils.parseDate(row.createdAt)}
    ]);
  },
});
</script>
