import { doAsyncAction } from "@/store/base/BaseAction";
import { Id } from "@/store/base/BaseEntity";
import baseMutations from "@/store/base/BaseMutations";
import BaseRequest, { CollectionParams } from "@/store/base/BaseRequest";
import { BaseState } from "@/store/base/BaseState";
import cloneDeep from "lodash.clonedeep";
import { Module } from "vuex";
import { News, NewsState } from "./types";

const newsRequests = new BaseRequest("/Public/News");

export const NewsModule: Module<NewsState, null> = {
  namespaced: true,
  state: new BaseState<News>(),
  mutations: {
    ...cloneDeep(baseMutations),
  },
  actions: {
    get(store, params: CollectionParams) {
      return doAsyncAction(store, {
        mutationType: "setCollection",
        action: () => newsRequests.getCollection(params)
      })
    },
    getSingle(store, id: Id) {
      return doAsyncAction(store, {
        mutationType: "setSelected",
        action: () => newsRequests.getSingle(id)
      })
    }
  }
}