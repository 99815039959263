import {doAsyncAction} from "@/store/base/BaseAction";
import baseMutations from "@/store/base/BaseMutations";
import BaseRequest, {CollectionParams} from "@/store/base/BaseRequest";
import {BaseState} from "@/store/base/BaseState";
import cloneDeep from "lodash.clonedeep";
import {Module} from "vuex";
import {RoleDto, RoleState} from "./types";

const roleRequests = new BaseRequest("/Admin/Role");

export const AdminRoleModule: Module<RoleState, null> = {
    namespaced: true,
    state: new BaseState<RoleDto>(),
    mutations: {
        ...cloneDeep(baseMutations),
    },
    actions: {
        get(store, params: CollectionParams) {
            return doAsyncAction(store, {
                mutationType: "setCollection",
                action: () => roleRequests.getCollection(params)
            })
        },
    }
}