import { doAsyncAction } from "@/store/base/BaseAction";
import { Id } from "@/store/base/BaseEntity";
import baseMutations from "@/store/base/BaseMutations";
import BaseRequest, { CollectionParams } from "@/store/base/BaseRequest";
import { BaseState } from "@/store/base/BaseState";
import cloneDeep from "lodash.clonedeep";
import { Module } from "vuex";
import {UserChangePasswordDto, UserLoginDto, UserSession, UserState, UserTokenDto} from "@/store/modules/auth/types";
import {UserRequest} from "@/store/modules/auth/request";
import Utils from "@/utils/utils";

const userRequests = new UserRequest();

export const AuthModule: Module<UserState, null> = {
  namespaced: true,
  state: new BaseState<UserSession>(),
  mutations: {
    ...cloneDeep(baseMutations),
    ...{
      login(_, data: UserTokenDto) {
        if(!data || !data.token) {
          return;
        }
        
        Utils.setAuthToken(data.token);
        location.reload();
      }
    }
  },
  actions: {
    setLogin(store, params: UserLoginDto) {
      return doAsyncAction(store, {
        mutationType: "login",
        action: () => userRequests.login(params.username, params.password)
      })
    },
    changePassword(store, params: UserChangePasswordDto) {
      return userRequests.changePassword(params.oldPassword, params.newPassword)
    },
    getSession(store) {
      return doAsyncAction(store, {
        mutationType: "setSelected",
        action: () => userRequests.getSession()
      })
    },
    logout(store) {
      Utils.removeAuthToken();
    }
  }
}