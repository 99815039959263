import { RouteRecordRaw } from "vue-router";
import HomeView from "@/views/Home.vue";
import { store } from "@/store";
import PageView from "@/views/Page.vue";
import News from "@/views/news/News.vue";

export const defaultRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: { name: "home", query: { skip: 0, take: 10, page: 1 } },
    },
    {
        path: '/home',
        name: 'home',
        meta: { title: "Strona Główna" },
        component: HomeView,
    },
    {
        path: '/logout',
        name: 'logout',
        beforeEnter: async (to, from, next) => {
            console.log(store);
            await store.dispatch('auth/logout');
            next({ name: 'home' })
            location.reload();
        },
        redirect: ''
    },
    {
        path: '/page/:id',
        name: 'page',
        meta: { title: "selectTitle" },
        component: PageView
    },
    {
        path: '/news/:id',
        name: 'news',
        component: News,
    },
    {
        path: "/redirect",
        name: "redirect",
        beforeEnter(to) {
            window.open((to.query as any).url, "_blank");
        },
        redirect: ''
    },
    {
        path: "/:catchAll(.*)",
        redirect: {
            name: 'home'
        }
    }
]