import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { trackRouter } from "vue-gtag-next";

import { adminRoutes } from "@/router/admin";
import { defaultRoutes } from "@/router/default";
import { userRoutes } from "@/router/user";


const routes: Array<RouteRecordRaw> = [
    ...adminRoutes,
    ...defaultRoutes,
    ...userRoutes
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

trackRouter(router)
export default router;
