import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-df114ad6")
const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "copyright" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bar = _resolveComponent("bar")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_bar, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, " Copyright © " + _toDisplayString(_ctx.currentYear) + " by Terminat and Sebastian. All rights reserved. ", 1)
        ])
      ]),
      _: 1
    })
  ]))
}