<template>
  <n-form :model="model" ref="formRef" :rules="rules">
    <n-form-item path="title" label="Tytuł">
      <n-input v-model:value="model.title" placeholder=""/>
    </n-form-item>

    <n-form-item path="body" label="Treść">
      <editor v-model="model.body" :init="editorConfig" :api-key="apiKey"/>
    </n-form-item>

    <n-form-item path="loggedOnly" label="Dostęp tylko dla zalogowanych">
      <n-switch v-model:value="model.loggedOnly" />
    </n-form-item>

    <div style="display: flex; justify-content: center;">
      <n-button
          type="primary"
          ghost
          @click="onFormSubmit"
      >
        Zapisz
      </n-button>
    </div>
  </n-form>
</template>

<script>
import {defineComponent, ref, computed, watch} from "vue";
import Editor from '@tinymce/tinymce-vue'
import {NFormItem, NForm, NInput, NButton, NSwitch} from 'naive-ui';

import {useStore} from '@/store';
import {TINYMCE_API_KEY} from "@/constants/constants";
import {useRoute, useRouter} from "vue-router";
import {editorConfig} from '@/utils/editor';

export default defineComponent({
  components: {Editor, NFormItem, NForm, NInput, NButton, NSwitch},
  setup() {
    const formRef = ref(null);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const model = ref({id: 0, body: '', title: '', loggedOnly: false})
    const selected = computed(() => store.state.adminPage.selected)

    const getSingle = (id) => {
      store.dispatch('adminPage/getSingle', id).then(() => {
        model.value = selected.value;
      });
    }

    if (route.query.id) {
      getSingle(+route.query.id);
    }

    watch(() => route.query.id, (newValue, oldValue) => {
      getSingle(+newValue);
    })

    return {
      model,
      formRef,
      apiKey: TINYMCE_API_KEY,
      editorConfig,
      rules: {
        title: {
          required: true,
          message: 'Podaj tytuł'
        },
        body: {
          required: true,
          message: 'Podaj treść'
        }
      },
      onFormSubmit: (e) => {
        e.preventDefault();
        formRef.value.validate((errors) => {
          if (errors) {
            return;
          }

          store.dispatch('adminPage/addOrUpdate', model.value).then(() => {
            router.push({name: 'admin-page'});
          })
        });
      }
    }
  },
});
</script>
