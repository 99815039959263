<template>
  <custom-card title="Zaloguj się" class="home-card">
    <n-form :model="model" ref="formRef" :rules="rules">
      <n-form-item path="username" label="Nazwa użytkownika">
        <n-input v-model:value="model.username" placeholder="" />
      </n-form-item>

      <n-form-item path="password" label="Hasło">
        <n-input v-model:value="model.password" placeholder="" type="password" />
      </n-form-item>

      <div style="display: flex; justify-content: center;">
        <n-button
            type="primary"
            ghost
            @click="onFormSubmit"
        >
          Zaloguj się
        </n-button>
      </div>
    </n-form>
  </custom-card>
</template>

<script>
import {NForm, NFormItem, NInput, NButton} from 'naive-ui';
import {defineComponent, ref} from 'vue'

import CustomCard from "@/components/CustomCard";

export default defineComponent({
  components: {CustomCard, NForm, NFormItem, NInput, NButton},
  setup(_, {emit}) {
    const formRef = ref(null);
    const model = ref({
      username: '',
      password: ''
    })

    return {
      model,
      formRef,
      rules: {
        username: {
          required: true,
          message: 'Podaj nazwę użytkownika'
        },
        password: {
          required: true,
          message: 'Wpisz hasło'
        }
      },
      onFormSubmit: (e) => {
        e.preventDefault();
        formRef.value.validate((errors) => {
          if (errors) {
            return;
          }

          emit('submit', model.value);
        });
      }
    }
  }
});
</script>

<style scoped>

</style>