
import { defineComponent } from "vue";
import NewsDescription from "components/NewsDescription.vue";

const CustomCard = defineComponent({
  components: {
    NewsDescription,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    w: {
      type: [String, Number],
    },
    h: {
      type: [String, Number],
    },
    newsInfo: {
      type: Object,
    },
  },
});

export default CustomCard;
