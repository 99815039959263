import { IBaseCollectionResponse, IBaseEntity } from "./BaseEntity";
import { IBaseState } from "./BaseState";
import cloneDeep from "lodash.clonedeep";

export interface IBaseMutations {
  startLoading(state: IBaseState<IBaseEntity>): void;
  endLoading(state: IBaseState<IBaseEntity>): void;
  setError(state: IBaseState<IBaseEntity>, e: string): void;
  setCollection(state: IBaseState<IBaseEntity>, collection: IBaseCollectionResponse): void;
  setSelected(state: IBaseState<IBaseEntity>, selected: IBaseEntity | null): void;
}

const baseMutations: IBaseMutations = {
  startLoading: (state: IBaseState<IBaseEntity>): void => {
    state.isLoading = true;
  },

  endLoading: (state: IBaseState<IBaseEntity>): void => {
    state.isLoading = false;
  },

  setError: (state: IBaseState<IBaseEntity>, e: string): void => {
    state.error = e;
  },

  setCollection: (state: IBaseState<IBaseEntity>, collection: IBaseCollectionResponse): void => {
    state.data = collection.data;
    state.total = collection.total;
  },

  setSelected: (state: IBaseState<IBaseEntity>, selected: IBaseEntity | null): void => {
    state.selected = selected;
  }
}

export default cloneDeep(baseMutations);

export type BaseMutationsKeys = keyof IBaseMutations;
