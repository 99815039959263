
import { defineComponent } from "vue";
import { NGrid, NGi } from "naive-ui";
import Utils from "@/utils/utils";
const Message = defineComponent({
  components: {
    NGrid,
    NGi,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    nickname: {
      type: String,
      required: true,
    },
    isUser: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    return {
      formattedDate: Utils.parseDate(props.date)
    }
  }
});

export default Message;
