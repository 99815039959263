<template>
  <div class="chat">
    <div>
      <message
          v-for="chatMessage of messages"
          :key="chatMessage.id"
          :message="chatMessage.message"
          :date="chatMessage.createdAt"
          :nickname="chatMessage.user.nickname"
          :isUser="user && chatMessage.user.id === user.id"
      />
    </div>

    <div class="chat__input-container">
      <n-form
          :label-width="80"
          :model="model"
          :rules="rules"
          size="medium"
          ref="formRef"
      >
        <n-form-item path="message">
          <n-input
              maxlength="250"
              show-count
              v-model:value="model.message"
              type="textarea"
              placeholder="Twoja wiadomość"
          />
        </n-form-item>
      </n-form>
    </div>
    <div class="chat__buttons">
      <n-space justify="end">
        <n-button size="small" type="primary" @click="sendMessage"
        >Wyślij
        </n-button
        >
        <n-button size="small" @click="refreshChatMessages">Odśwież</n-button>
      </n-space>
    </div>
  </div>
</template>

<script>
import {useStore} from "@/store";
import {ChatMessage} from "@/store/modules/chat/types";
import Utils from "@/utils/utils";
import {computed, defineComponent, ref} from "vue";
import {NInput, NSpace, NButton, NForm, NFormItem} from "naive-ui";
import {useRouter} from "vue-router";
import Message from "components/Message.vue";

const Chat = defineComponent({
  components: {NInput, NSpace, NButton, Message, NForm, NFormItem},
  setup() {
    const store = useStore();
    const router = useRouter();
    const formRef = ref(null);
    const refreshChatMessages = () => store.dispatch("chat/get", {take: 5});
    const model = ref({message: ''});

    const sendMessage = (e) => {
      e.preventDefault();

      formRef.value.validate((errors) => {
        if (errors) {
          window.$message.error('Występują błędy w formularzu.')
          return;
        }

        store
            .dispatch("chat/add", model.value)
            .then(() => refreshChatMessages());
      })
    };
    refreshChatMessages();

    return {
      formRef,
      messages: computed(() => store.state.chat.data),
      user: computed(() => store.state.auth.selected),
      sendMessage,
      model,
      refreshChatMessages,

      rules: {
        message: {
          required: true,
          message: "Twoja wiadomość nie może być pusta",
          trigger: ['input', 'blur']
        },
      },
    };
  },
});

export default Chat;
</script>


<style scoped>
.chat {
  font-size: var(--chat-font-size);
}

.chat__input-container {
  margin-top: var(--margin-default);
}

.chat__buttons {
  margin-top: var(--margin-small);
}
</style>