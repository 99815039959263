export enum DomainExceptionCode
{
    Forbidden,
    NotFound,
    OldPasswordIsWrong,
    UserNotFoundOrBadPassword
}

export const DomainExceptionCodeTranslation = {
    [DomainExceptionCode.Forbidden]: 'Zabronione',
    [DomainExceptionCode.NotFound]: 'Taki element nie istnieje',
    [DomainExceptionCode.OldPasswordIsWrong]: 'Poprzednie hasło jest nieprawidłowe',
    [DomainExceptionCode.UserNotFoundOrBadPassword]: 'Użytkownik nie istnieje lub złe hasło'
}