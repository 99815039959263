
import { defineComponent } from "vue";

const Standard = defineComponent({
  components: {},
  props: {
    _html: {
      type: String,
    },
  },
});

export default Standard;
