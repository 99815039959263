
import { computed, defineComponent, watch } from "vue";
import { useStore } from "@/store";

import CustomCard from "components/CustomCard.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    CustomCard,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    watch(
      () => route.params.id,
      (newValue) => {
        if (route.params.id) {
          store.dispatch("page/getSingle", +newValue);
        }
      }
    );

    store.dispatch("page/getSingle", +route.params.id);

    return {
      page: computed(() => store.state.page.selected),
    };
  },
});
