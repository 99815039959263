<template>
  <n-button class="text-right">
    <custom-link :to="{name: 'admin-menu-edit'}">Dodaj nowy</custom-link>
  </n-button>

  <n-data-table remote :columns="columns" :data="list.data" :pagination="pagination" :loading="loading"
                @update:page="onPageChange"/>
</template>

<script>
import {defineComponent} from "vue";
import {NDataTable, NButton} from 'naive-ui'

import {usePagination} from "@/utils/pagination";
import CustomLink from "components/CustomLink.vue";


export default defineComponent({
  components: {NDataTable, NButton, CustomLink},
  setup() {
    return usePagination('Menu', [
      {title: 'Id', key: 'id'},
      {title: 'Tytuł', key: 'title'},
      {title: 'Kolejność', key: 'order'},
      {title: 'Przekierowanie', key: 'redirect'}
    ], true);
  },
});
</script>
