<template>
  <custom-card :title="'Użytkownik: ' + user.nickname" class="home-card">
    <div>
      <custom-navigation :menu-options="userMenu"/>
    </div>
  </custom-card>

  <custom-card v-if="isAdmin" title="Panel administracyjny" class="home-card">
    <div>
      <custom-navigation :menu-options="adminMenu"/>
    </div>
  </custom-card>
</template>

<script>
import {computed, defineComponent} from "vue";

import {
  NewspaperOutline,
  PersonOutline,
  LogOutOutline,
  MenuOutline,
  LogoWebComponent,
  CopyOutline,
  LockClosed
} from "@vicons/ionicons5";

import CustomCard from "@/components/CustomCard";
import {getMenuOptions} from "@/utils/menu";
import CustomNavigation from "@/components/CustomNavigation";

const userMenu = getMenuOptions([
  {
    redirect: {
      name: 'user-change-password'
    },
    title: 'Zmień hasło',
    key: 'change-password',
    icon: LockClosed
  },
  {
    redirect: {
      name: "logout",
    },
    title: "Wyloguj się",
    key: "logout",
    icon: LogOutOutline,
  },
]);

const adminMenu = getMenuOptions([
  {
    redirect: {
      name: "admin-news",
    },
    icon: NewspaperOutline,
    title: "Zarządzanie newsami",
  },
  {
    redirect: {
      name: "admin-panel",
    },
    icon: CopyOutline,
    title: "Zarządzanie panelami",
  },
  {
    redirect: {
      name: "admin-menu",
    },
    icon: MenuOutline,
    title: "Zarządzanie menu",
  },
  {
    redirect: {
      name: "admin-page",
    },
    title: "Zarządzanie stronami",
    icon: LogoWebComponent,
  },
  {
    redirect: {
      name: "admin-user",
    },
    icon: PersonOutline,
    title: "Zarządzanie użytkownikami",
  }
]);

export default defineComponent({
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {CustomNavigation, CustomCard},
  setup(props) {
    return {
      adminMenu,
      userMenu,
      isAdmin: computed(() => props.user.roles.includes('Administrator'))
    };
  },
});
</script>
