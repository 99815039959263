import { IBaseEntity } from "./BaseEntity";

export interface IBaseState<Item extends IBaseEntity> {
  data: Item[];
  selected: Item | null;
  isLoading: boolean;
  error: string;
  total: number;
}

export class BaseState<Item extends IBaseEntity> implements IBaseState<Item> {
  data = [];
  selected = null;
  isLoading = false;
  error = '';
  total = 0;
}
