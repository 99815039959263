<template>
  <n-form :model="model" ref="formRef" :rules="rules">
    <n-form-item path="email" label="Adres e-mail">
      <n-input v-model:value="model.email" placeholder=""/>
    </n-form-item>

    <n-form-item path="nickname" label="Nazwa użytkownika">
      <n-input v-model:value="model.nickname" placeholder=""/>
    </n-form-item>

    <n-form-item path="password" label="Hasło">
      <n-input v-model:value="model.password" :type="passwordInputType" placeholder=""/>
      <n-button @click="generatePassword">
        <n-icon>
          <dice />
        </n-icon>
      </n-button>
      
      <n-button @click="togglePasswordVisible">
        <n-icon>
          <eye-off v-if="showPassword"/>
          <eye v-else/>
        </n-icon>
      </n-button>
    </n-form-item>

    <div style="display: flex; justify-content: center;">
      <n-button
          type="primary"
          ghost
          @click="onFormSubmit"
      >
        Zapisz
      </n-button>
    </div>
  </n-form>
</template>

<script>
import {defineComponent, ref, computed, watch} from "vue";
import {NFormItem, NForm, NInput, NButton, NIcon} from 'naive-ui';
import {EyeOff, Eye, Dice} from "@vicons/ionicons5";

import {useStore} from '@/store';
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  components: {NFormItem, NForm, NInput, NButton, EyeOff, Eye, NIcon, Dice},
  setup() {
    const formRef = ref(null);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const model = ref({id: 0, body: '', title: '', order: 0, location: 0, type: 0, loggedOnly: false})
    const selected = computed(() => store.state.adminUser.selected)
    const showPassword = ref(false);

    const getSingle = (id) => {
      store.dispatch('adminUser/getSingle', id).then(() => {
        model.value = selected.value;
      });
    }

    if (route.query.id) {
      getSingle(+route.query.id);
    }

    watch(() => route.query.id, (newValue, oldValue) => {
      getSingle(+newValue);
    })

    return {
      model,
      formRef,
      rules: {
        email: {
          required: true,
          message: 'Podaj adres e-mail'
        },
        nickname: {
          required: true,
          message: 'Podaj nazwę użytkownika'
        },
        password: {
          required: true,
          message: 'Podaj hasło'
        }
      },
      showPassword,
      passwordInputType: computed(() => showPassword.value ? 'text' : 'password'),
      togglePasswordVisible: () => {
        showPassword.value = !showPassword.value;
      },
      generatePassword: () => {
        model.value.password = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-8);
        showPassword.value = true;
      },
      onFormSubmit: (e) => {
        e.preventDefault();
        formRef.value.validate((errors) => {
          if (errors) {
            return;
          }

          store.dispatch('adminUser/addOrUpdate', model.value).then(() => {
            router.push({name: 'admin-user'});
          })
        });
      }
    }
  },
});
</script>
