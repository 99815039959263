
import { PanelTypeName } from "@/store/modules/panels/types";
import { defineComponent, h } from "vue";

import CustomCard from "components/CustomCard.vue";

import Chat from "components/Panels/Chat.vue";
import Menu from "components/Panels/Menu.vue";
import Standard from "components/Panels/Standard.vue";

const components: { [key: string]: any } = {
  Menu,
  Standard,
  Chat,
};

const Panel = defineComponent({
  components,
  props: {
    componentProps: {
      type: Object,
      required: true,
    },
    name: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  render() {
    return h(CustomCard, { title: this.title }, () =>
      h(components[PanelTypeName[this.name]], this.componentProps)
    );
  },
});

export default Panel;
