import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_card = _resolveComponent("custom-card")!
  const _component_comments = _resolveComponent("comments")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.news)
      ? (_openBlock(), _createBlock(_component_custom_card, {
          key: 0,
          title: _ctx.news.title,
          newsInfo: {
      addedBy: _ctx.news.user?.nickname || 'admin',
      addedDate: _ctx.news.createdAt,
    }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.news.body
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["title", "newsInfo"]))
      : _createCommentVNode("", true),
    _createVNode(_component_comments)
  ], 64))
}