import { AxiosResponse } from 'axios';
import axios from '@/utils/axios';
import { IBaseEntity, Id } from './BaseEntity';

export interface RequestOptions {
    linkedEntityUrl: Id;

}

export interface CollectionParams {
    skip?: number;
    take?: number;
}

export default class BaseRequest {
    route: string;

    constructor(route: string) {
        this.route = route;

    }

    replaceRoute(linkedEntityId?: Id): string {
        if (linkedEntityId) {
            const re = /\{(.*?)\}/;
            return this.route.replace(re, '' + linkedEntityId);
        } else {
            return this.route;
        }
    }

    getCollection<Response>(params?: CollectionParams, options?: RequestOptions, url?: string): Promise<AxiosResponse<Response>> {
        return axios.get<Response>(url || this.replaceRoute(options?.linkedEntityUrl), {
            params,
        });
    }

    getSingle<Response>(id: Id, options?: RequestOptions, url?: string): Promise<AxiosResponse<Response>> {
        return axios.get<Response>(url || this.replaceRoute(options?.linkedEntityUrl) + `/${id}`);
    }

    create<Response, Item extends IBaseEntity>(item: Item, options?: RequestOptions, url?: string): Promise<AxiosResponse<Response>> {
        return axios.post<Response>(url || this.replaceRoute(options?.linkedEntityUrl), item);
    }

    update<Response, Item extends IBaseEntity>(item: Item, options?: RequestOptions, url?: string): Promise<AxiosResponse<Response>> {
        return axios.put<Response>(url || this.replaceRoute(options?.linkedEntityUrl) + `/${item.id}`, item);
    }

    delete<Response>(id: Id, options?: RequestOptions, url?: string): Promise<AxiosResponse<Response>> {
        return axios.delete<Response>(url || this.replaceRoute(options?.linkedEntityUrl) + `/${id}`);
    }

    patch<Response>(id: Id, active: boolean, options?: RequestOptions, url?: string): Promise<AxiosResponse<Response>> {
        return axios.patch<Response>(url || this.replaceRoute(options?.linkedEntityUrl) + `/${id}`, { active });
    }
}