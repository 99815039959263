
import { computed, defineComponent } from "vue";

import CustomLink from "components/CustomLink.vue";
import Utils from "../utils/utils";
import {getCommentCountDescription} from "@/utils/comment";

const CustomCard = defineComponent({
  components: {
    CustomLink,
  },
  props: {
    addedBy: {
      type: String,
      required: true,
    },
    addedDate: {
      type: String,
      required: true,
    },
    commentsCount: {
      type: Number,
      required: true,
    },
    commentsTo: {
      type: [Object, String],
    },
  },
  setup(props) {
    return {
      formatDate: computed(() => Utils.parseDate(props.addedDate)),
      commentCount: computed(() =>getCommentCountDescription(props.commentsCount)),
    };
  },
});

export default CustomCard;
