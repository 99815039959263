import {h} from "vue";

import CustomLink from "@/components/CustomLink.vue";
import {renderIcon} from "@/utils/icons";

export interface MenuItem {
    icon?: any;
    title: string;
    redirect: string | any;
    key: string;
    disabled?: boolean;
}

export function getMenuOptions(items: MenuItem[] = []) {
    return items.map((item) => ({
        label: () => h(
            CustomLink,
            {
                to: item.redirect,
            },
            () => item.title
        ),
        icon: item.icon ? renderIcon(item.icon) : null,
        key: item.key,
        name: item.key,
        disabled: item.disabled
    }));
}