
import { computed, defineComponent } from "vue";
import { NMenu } from "naive-ui";
import { useRoute } from "vue-router";

const CustomNavigation = defineComponent({
  components: {
    NMenu,
  },
  props: {
    menuOptions: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const route = useRoute();
    return {
      currentRouteName: computed(() => route.name),
    };
  },
});

export default CustomNavigation;
