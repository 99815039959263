import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { MenuModule } from './modules/menu'
import { NewsModule } from './modules/news'
import { PanelsModule } from './modules/panels'
import { AuthModule } from "@/store/modules/auth";
import { AdminMenuModule } from "@/store/modules/menu/admin";
import { AdminNewsModule } from "@/store/modules/news/admin";
import { AdminPanelsModule } from "@/store/modules/panels/admin";
import { AdminPageModule } from "@/store/modules/page/admin";
import { PageModule } from "@/store/modules/page";
import { AdminUserModule } from "@/store/modules/user/admin";
import { AdminRoleModule } from "@/store/modules/role/admin";
import { ChatModule } from './modules/chat'
import {CommentsModule} from "@/store/modules/comments";

const modules = {
    panel: PanelsModule,
    menu: MenuModule,
    news: NewsModule,
    auth: AuthModule,
    page: PageModule,
    chat: ChatModule,
    adminMenu: AdminMenuModule,
    adminNews: AdminNewsModule,
    adminPanel: AdminPanelsModule,
    adminPage: AdminPageModule,
    adminUser: AdminUserModule,
    adminRole: AdminRoleModule,
    comments: CommentsModule
}

export type StateModules = typeof modules;

export type State = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [key in keyof StateModules]: Exclude<typeof modules[key]["state"], Function | undefined>
}

export const key: InjectionKey<Store<State>> = Symbol()
export const store = createStore({
    modules,
})

export function useStore(): Store<State> {
    return baseUseStore(key)
}