
import {computed, defineComponent} from "vue";

const CustomLink = defineComponent({
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
  setup(props) {
    return {
      data: computed(() => {
        if (typeof props.to === 'string') {
          if (props.to.startsWith('http')) {
            return {name: 'redirect', query: {url: props.to}};
          }

          return {path: props.to}
        }

        return props.to;
      })
    }
  }
});

export default CustomLink;
