<template>
  <n-message-provider placement="bottom" />
</template>
<script>
import { defineComponent } from "vue";
import { useMessage, NMessageProvider } from "naive-ui";

export default defineComponent({
  components: {
    NMessageProvider,
  },
  setup() {
    window.$message = useMessage();

    return {};
  },
});
</script>

<style scoped>
</style>