<template>
  <div class="comments__user-comment">
    <span class="comments__nickname">{{ comment.user.nickname }}</span>
    <div class="comments__comment-date">{{ formattedDate }}</div>
    <div class="comments__body">{{ comment.message }}</div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import Utils from "utils/utils";

export default defineComponent({
  props: {
    comment: Object
  },
  setup(props) {
    return {
      formattedDate: Utils.parseDate(props.comment.createdAt)
    }
  }
})
</script>

<style scoped>

.comments__user-comment {
  border-bottom: 1px solid var(--gray-border);
  padding-bottom: var(--padding-medium);
  margin-bottom: var(--margin-default);
}

.comments__nickname {
  font-weight: bold;
  font-size: var(--comment-nickname);
}

.comments__comment-date {
  color: var(--font-gray-color);
  font-size: var(--comment-date);
}

.comments__body {
  margin-top: var(--margin-small);
  word-break: break-word;
}
</style>