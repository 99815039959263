import { AxiosResponse } from "axios";
import { ActionContext } from "vuex";
import { BaseMutationsKeys, } from "./BaseMutations";

export interface AsyncActionConfig<MutationKeys extends string> {
    action: () => Promise<AxiosResponse>;
    mutationType?: MutationKeys | BaseMutationsKeys;
}

export const doAsyncAction = <MutationKeys extends string, State>(store: ActionContext<State, State>, config: AsyncActionConfig<MutationKeys>): Promise<any> => {
    store.commit("startLoading");

    return config.action().then((response) => {
        if (config.mutationType) {
            store.commit(config.mutationType, response.data);
        }
    }).catch((e) => {
        store.commit("setError", e);
    }).finally(() => {
        store.commit("endLoading");
    })
}



